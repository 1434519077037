<template lang="html">
  <div class="">
    <Navbar></Navbar>
    <v-container grid-list-xs,sm,md,lg,xl class="pt-5 pab">
      <v-layout  wrap justify-center pb-3>
        <v-flex xs12 class="headline text-xs-center" >
          Rate your stay
        </v-flex>
      </v-layout>
      <v-layout  wrap justify-center>
        <v-flex xs12 sm10 md7>
          <v-layout  wrap justify-center>
            <v-card v-if="cardNumber==1" width="90%">

              <v-card-title justify-center primary-title>
                <v-layout  wrap justify-center>

                  How was your overall experience?
                </v-layout>
              </v-card-title>
              <v-card-text class="text-xs-center">
                <v-layout  wrap>
                  <v-flex xs12>
                    Feedback about your room is super important for us to be able to suggest only the best rooms and for the future tenants who would like to have any idea of what the room is like in real life. You will be able to complete your ratings with notes and suppoorting pictures if you wish.

                  </v-flex>
                </v-layout>
                <v-layout pt-4  wrap justify-center>
                  <v-rating half-increments large color:primary hover @input="setRating(general,'general')" v-model="general"></v-rating>
                </v-layout>
                <v-layout pt-4  px-3 wrap>
                  <v-flex xs12 class="py-3">
                    <v-textarea
                      label="Please share some general info about the place you stayed at"
                      auto-grow
                      outline
                      v-model="generalText"
                    ></v-textarea>
                  </v-flex>
                </v-layout>

              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" :disabled="firstNextDisabled" @click="nextCard('general','generalText')">Next</v-btn>
              </v-card-actions>
            </v-card>
            <v-card v-if="cardNumber==2" width="90%">
              <v-card-title justify-center primary-title>
                <v-layout  wrap justify-center>
                  How would you rate your location?
                </v-layout>
              </v-card-title>
              <v-card-text class="text-xs-center">
                <v-layout  wrap>
                  <v-flex xs12>
                    Was your location as expected? Do you think your location was a good place to live?
                  </v-flex>
                </v-layout>
                <v-layout pt-4  wrap justify-center>
                  <v-rating half-increments large color:primary hover @input="setRating(location, 'location')" v-model="location"></v-rating>
                </v-layout>
                <v-layout  px-3  wrap>
                  <v-flex xs12 class="py-3">
                    <v-textarea
                      label="What were the best and worst things about your location?"
                      auto-grow
                      outline
                      v-model="locText"
                    ></v-textarea>
                  </v-flex>
                </v-layout>
              </v-card-text>
              <v-card-actions>
                <v-btn color="secondary" @click="cardNumber--">back</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="nextCard('location','locText')">Next</v-btn>
              </v-card-actions>
            </v-card>
            <v-card v-if="cardNumber==3" width="90%">
              <v-card-title justify-center primary-title>
                <v-layout  wrap justify-center>
                  How would you rate your Landlord?
                </v-layout>
              </v-card-title>
              <v-card-text class="text-xs-center">
                <v-layout  wrap>
                  <v-flex xs12>
                    Was your landlord/flatmanager friendly, reactive and helpful?
                  </v-flex>
                </v-layout>
                <v-layout pt-4  wrap justify-center>
                  <v-rating half-increments large color:primary hover @input="setRating(landlord, 'landlord')" v-model="landlord"></v-rating>
                </v-layout>
                <v-layout  px-3  wrap>
                  <v-flex xs12 class="title">
                    Landlord
                  </v-flex>
                  <v-flex xs12 class="py-3">
                    <v-textarea
                      label="Please provide us with some feedback about your landlord"
                      auto-grow
                      outline
                      v-model="llText"
                    ></v-textarea>
                  </v-flex>
                </v-layout>
              </v-card-text>
              <v-card-actions>
                <v-btn color="secondary" @click="cardNumber--">back</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="nextCard('landlord','llText')">Next</v-btn>
              </v-card-actions>
            </v-card>
            <v-card v-if="cardNumber==4" width="90%">
              <v-card-title justify-center primary-title>
                <v-layout  wrap justify-center>
                  Cleanliness of the listing
                </v-layout>
              </v-card-title>
              <v-card-text class="text-xs-center">
                <v-layout  wrap>
                  <v-flex xs12>
                    How would you rate the cleanliness of the apartment.
                  </v-flex>
                </v-layout>
                <v-layout pt-4  wrap justify-center>
                  <v-rating half-increments large color:primary hover @input="setRating(cleanliness, 'cleanliness')" v-model="cleanliness"></v-rating>
                </v-layout>
                <v-layout  px-3  wrap>
                  <v-flex xs12 class="py-3">
                    <v-textarea
                      label="What are your comments regarding the cleanliness"
                      auto-grow
                      outline
                      v-model="cleanText"
                    ></v-textarea>
                  </v-flex>
                </v-layout>
              </v-card-text>
              <v-card-actions>
                <v-btn color="secondary" @click="cardNumber--">back</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="nextCard('cleanliness','cleanText')">Next</v-btn>
              </v-card-actions>
            </v-card>

            <v-card v-if="cardNumber==5" width="90%">
              <v-card-title justify-center primary-title>
                <v-layout  wrap justify-center>
                  Accuracy of the listing
                </v-layout>
              </v-card-title>
              <v-card-text class="text-xs-center">
                <v-layout  wrap>
                  <v-flex xs12>
                    Where the pictures accurate or outdated? Did you find the pictures represent reality?
                    <v-btn color="primary" :href="linkToRoom" target="_blank">See your listing</v-btn>
                  </v-flex>
                </v-layout>
                <v-layout pt-4  wrap justify-center>
                  <v-rating half-increments large color:primary hover @input="setRating(accuracy, 'accuracy')" v-model="accuracy"></v-rating>
                </v-layout>
                <v-layout  px-3  wrap>
                  <v-flex xs12 class="py-3">
                    <v-textarea
                      label="Was there anything different than on the listing?"
                      auto-grow
                      outline
                      v-model="accuracyText"
                    ></v-textarea>
                  </v-flex>
                </v-layout>

              </v-card-text>
              <v-card-actions>
                <v-btn color="secondary" @click="cardNumber--">back</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="nextCard('accuracy','accuracyText')">Next</v-btn>
              </v-card-actions>
            </v-card>

            <v-card v-if="cardNumber==6" width="90%">
              <v-card-title justify-center primary-title>
                <v-layout  wrap justify-center>
                  Would you recommend this listing?
                </v-layout>
              </v-card-title>
              <v-card-text class="text-xs-center">
                <v-layout  wrap>
                  <v-flex xs12>
                    How strongly would you recommend this flat to someone else? (5 stars is an absolute yes)
                  </v-flex>
                </v-layout>
                <v-layout pt-4  wrap justify-center>
                  <v-rating half-increments large color:primary hover @input="setRating(recc, 'recc')" v-model="recc"></v-rating>
                </v-layout>
              </v-card-text>
              <v-card-actions>
                <v-btn color="secondary" @click="cardNumber--">back</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="cardNumber++">Next</v-btn>
              </v-card-actions>
            </v-card>

            <v-card v-if="cardNumber==7" width="90%">
              <v-card-title justify-center primary-title>
                <v-layout  wrap justify-center>
                  Do you have any photos to add?
                </v-layout>
              </v-card-title>
              <v-card-text >
                <v-layout  wrap>
                  <v-flex xs12 class="text-xs-center">
                    A photo says more than a thousand words.
                  </v-flex>
                </v-layout>
                <v-layout px-3  wrap>
                  <v-flex xs12 class="pt-3">

                    <RatingDropzone id="photos" @remove="removePhoto" @onUpload="uploadPhotos" :landlordId="user.id" :photos="myPhotos" @addPhoto='addPhoto($event,"Room")'>
                    </RatingDropzone>
                  </v-flex>

                </v-layout>
              </v-card-text>
              <v-card-actions>
                  <v-btn color="secondary" @click="cardNumber--">back</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="finish()">Finish</v-btn>
              </v-card-actions>
            </v-card>
          </v-layout>
          <v-layout  wrap pt-3 justify-center>

            <v-flex 12 class="text-xs-center">
              Step {{cardNumber}}/{{totalCards}}
            </v-flex>
          </v-layout>
          <v-layout  wrap justify-center>

            <v-flex xs6 class="py-3">
              <v-progress-linear
              color="primary"
              height="5"

              v-model="completion"
              ></v-progress-linear>
            </v-flex>

          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
    <v-dialog
         v-model="showThanks"
         max-width="290"
       >
         <v-card>
           <v-card-title justify-center class="headline text-xs-center">
             <v-layout wrap>
               <v-flex xs12 class="text-xs-center">
                 <v-icon>favorite</v-icon><span>  THANK YOU  </span><v-icon>favorite</v-icon>

               </v-flex>

             </v-layout>
           </v-card-title>

           <v-card-text>
             Thank you so much for your review. <br>We greatly appreciate the time you spent making this review. Future tenants will love you for it!
           </v-card-text>

           <v-card-actions>
             <v-spacer></v-spacer>
             <v-btn
               color="primary"
               flat="flat"
               to="/"
             >
               Cheers
             </v-btn>
           </v-card-actions>
         </v-card>
       </v-dialog>
  </div>
</template>

<script>
import firebase from '../../FirebaseApp'
import axios from 'axios'
import moment from 'moment'
import RatingDropzone from './RatingDropzone.vue'
import * as types from '../../store/types'
import {
  mapGetters
} from 'vuex'

export default {
  data: () => ({
    cardNumber:1,
    general:4.5,
    landlord:4.5,
    location:4.5,
    accuracy:4.5,
    cleanliness:4.5,
    recc:4.5,
    totalCards: 7,
    generalOpts:[
      'I had a great time in Taipei and in this apartment. Thank you!',
      'I definitely recommend this apartment to future students and young professionals.',
      'I really enjoyed my stay in Taiwan. This apartment was a good place to live and to relax.',
      'I recommend this place. It is a good place to stay during your adventure in Taiwan.',
      'This is the kind of places that I would recommend to a friend.',
      'If a friend is coming to Taipei, I will probably recommend this apartment.'
    ],
    generalText:"",
    llOpts:[
      'The landlord was great!',
      'Nice landlord',
      'The landlord was cool',
      'The landlord was friendly',
      'Very welcoming landlord',
      'Great landlord',
      'The landlord is friendly and reactive',
      'Welcoming and friendly landlord',
      'I did not have any problem with the landlord',
      'I recommend this landlord. Good experience',
      'I recommend this landlord. Great experience'
    ],
    llText:"",
    accuracyOpts:[
      'The apartment was similar to the pictures and the description',
      'The description was correct',
      'The room was like expected, no bad surprises.',
      'The room was like expected',
      'No bad surprise, the description was correct',
      'The description was accurate',
      'Correct description',
      'Accurate description',
      'The combination of pictures and description was sufficient to have a good idea of what to expect.'
    ],
    accuracyText:"",
    locText:"",
    cleanText:"",
    photos:[],
    rating:{},
    loading:true,
    firstNextDisabled:false,
    hasOrder:false,
    showThanks:false,
  }),
  firebase() {
    return {
      booking: {
        source: firebase.database().ref('bookingRequests/'+this.user.bookingRequestOut),
        asObject:true,
        readyCallback: function() {
          this.loading=false

          if (this.booking['.key']==undefined|| this.booking['.key']=='undefined' || this.booking.hasOwnProperty('orderId')==false || this.booking.status.toLowerCase()=='canceled'){
            this.hasOrder=false
            // alert("You don't have a booking with us")
            // this.$router.push('/')

          } else {
            this.hasOrder=true
            this.firstNextDisabled=false
          }
        }
      },
      myPhotos:{
        source: firebase.database().ref('reviewPhotos').orderByChild('tenant').equalTo(this.user.id),
        readyCallback:function(){
          this.photos=JSON.parse(JSON.stringify(this.myPhotos))
          console.log('set base My Photos');
        }
      },
      ratings:{
        source: firebase.database().ref('ratings').orderByChild('tenant').equalTo(this.user.id),
        readyCallback:function(){
          console.log('setBase rating');
          // this.rating=JSON.parse(JSON.stringify(this.ratings))
          if (this.ratings.length>0){
            for (var i = 0; i < this.ratings.length; i++) {
              if (this.ratings[i].room==this.roomId){
                this.cardNumber++
                switch (this.ratings[i].rItem.toLowerCase()) {
                  case 'general':
                  this.general=this.ratings[i].rVal
                  break;
                  case 'location':
                  this.location=this.ratings[i].rVal
                  break;
                  case 'accuracy':
                  this.accuracy=this.ratings[i].rVal
                  break;
                  case 'cleanliness':
                    this.cleanliness=this.ratings[i].rVal
                    break;
                  case 'recc':
                  this.recc=this.ratings[i].rVal
                  break;
                  default:
                  console.log('ERROR');
                  break
                }
              }
            }
          }

        }
      },
      reviews:{
        source: firebase.database().ref('reviews').orderByChild('tenant').equalTo(this.user.id),
        readyCallback:function(){
          console.log('set base review');
          if (this.reviews.length>0){
            for (var i = 0; i < this.reviews.length; i++) {
              if (this.reviews[i].room==this.roomId){
                console.log(this.reviews[i].rItem);
                switch (this.reviews[i].rItem.toLowerCase()) {
                  case 'generaltext':
                  this.generalText=this.reviews[i].rText
                  break;
                  case 'lltext':
                  this.llText=this.reviews[i].rText
                  break;
                  case 'accuracytext':
                    this.accuracyText=this.reviews[i].rText
                    break;
                  case 'loctext':
                    this.locText=this.reviews[i].rText
                    break;
                  case 'cleantext':
                    this.cleanText=this.reviews[i].rText
                    break;

                  default:
                  console.log('ERROR');
                  break
                }
              }
            }
          }
        }
      },
    }
  },
  methods: {
    //Add a photo to the DB And to the current array of photos.
    uploadPhotos(x){
      console.log(this.roomId);
      let t={
        id:x.id,
        room:this.roomId,
        tenant:this.user.id
      }
      axios.post('reviewPhotos.json?auth='+this.$store.state.auth.idToken,t)
    },
    finish(){
      axios.patch('users/'+this.user.id+'.json?auth='+this.$store.state.auth.idToken,{reviewed:true})
      this.showThanks=true
    },
    removePhoto(photo){
      if (photo['.key']!="" && photo['.key']!=undefined){
        console.log('removePhoto', photo);
        axios.delete('reviewPhotos/'+photo['.key']+'.json?auth='+this.$store.state.auth.idToken)
      } else {
          console.log( 'problem:',photo);
      }
    },
    nextCard(ratingItem, reviewItem){
      this.setRating(this[ratingItem],ratingItem)
      this.setSingleReview(this[reviewItem],reviewItem)
      this.cardNumber++
    },
    //post the rating to the DB
    setRating(value, item) {
      console.log(this.roomId, this.type, this.landlordId);
      if (this.roomId!=null && this.type!=null && this.landlordId!=null){
        // this.cardNumber++
        let rating={
          room:this.roomId,
          ll: this.landlordId,
          tenant:this.user.id,
          type:this.type,
          rVal:value,
          rItem:item,
          ts:moment()
        }
        console.log('set the rating on',item, value);
        let j=this.ratings.findIndex(rt=>{
          return rt.rItem==item && rt.room==this.roomId
        })
        console.log('j=',j);
        if (j!=-1){ //it already exists, so patch instead of post
          console.log('patch rating');
          // axios.patch('ratings/'+this.ratings[j]['.key']+'.json?auth='+this.$store.state.auth.idToken,rating)
        } else { //Means the item does not exist in the reviews array -->  not posted yet
          console.log('post rating', rating);
          axios.post('ratings.json?auth='+this.$store.state.auth.idToken,rating)
        }
      } else if (this.loading==true){
        alert('We are still loading your information.')
        // this.firstNextDisabled=true
        // this.firstNextDisabled=true
      } else if (this.loading==false && this.hasOrder==false){
        alert("Something went wrong with your link, please contact hello@myroomabroad.com'")
        // this.$router.push('/')
      }
    },
    setSingleReview(value,item){
      if (this.roomId!=null && this.type!=null && this.landlordId!=null){

        if (value!=""){
          console.log(value, );
          let j=-1
          if (this.reviews.length>0){
            j=this.reviews.findIndex(review=>{
              return review.rItem==item
            })
          }
          if (j!=-1){ //it already exists, so patch instead of post
            // t.rItem=items[i]
            // t.rText=this[items[i]]
            console.log('patch review');

            axios.patch('reviews/'+this.reviews[j]['.key']+'.json?auth='+this.$store.state.auth.idToken,{
              ts:moment(),
              ll:this.landlordId,
              tenant:this.user.id,
              type:this.type,
              room:this.roomId,
              rItem:item,
              rText:value,
            })
            console.log('patch this ',this.reviews[j][',key']);
          } else {
            console.log('post', {
              ts:moment(),
              ll:this.landlordId,
              tenant:this.user.id,
              type:this.type,
              room:this.roomId,
              rItem:item,
              rText:value,
            } );
            axios.post('reviews.json?auth='+this.$store.state.auth.idToken,{
              ts:moment(),
              ll:this.landlordId,
              tenant:this.user.id,
              type:this.type,
              room:this.roomId,
              rItem:item,
              rText:value,
            })
          }
        }
      }
    },
    //post a review to the DB
    setReview(){
      console.log('setReview');
      let items=['generalText','llText','accuracyText','locText','cleanText']
      let j=-1
      for (var i = 0; i < items.length; i++) {
        console.log(items[i],this[items[i]],'in for');

        if (this[items[i]]!= null){
          //set the item and its text value
          localStorage.removeItem(items[i])
          //check if the review exists
          if (this.reviews.length>0){
            j=this.reviews.findIndex(review=>{
              return review.rItem==items[i]
            })
            console.log(j);
            if (j!=-1){ //it already exists, so patch instead of post
              // t.rItem=items[i]
              // t.rText=this[items[i]]
              console.log('patch review');

              axios.patch('reviews/'+this.reviews[j]['.key']+'.json?auth='+this.$store.state.auth.idToken,{
                ts:moment(),
                ll:this.landlordId,
                tenant:this.user.id,
                type:this.type,
                room:this.roomId,
                rItem:items[i],
                rText:this[items[i]]
              })
            } else { //Means the item does not exist in the reviews array -->  not posted yet
              // console.log(i , t.rItem, items[i],this[items[i]]);
              // console.log('post review',t);
              // t.rItem=items[i]
              // t.rText=this[items[i]]
              axios.post('reviews.json?auth='+this.$store.state.auth.idToken,{
                ts:moment(),
                ll:this.landlordId,
                tenant:this.user.id,
                type:this.type,
                room:this.roomId,
                rItem:items[i],
                rText:this[items[i]]
              })
            }
          } else {
            // console.log(i , t.rItem);
            // t.rItem=items[i]
            // t.rText=this[items[i]]
            // console.log('post review',t);
            axios.post('reviews.json?auth='+this.$store.state.auth.idToken,{
              ts:moment(),
              ll:this.landlordId,
              tenant:this.user.id,
              type:this.type,
              room:this.roomId,
              rItem:items[i],
              rText:this[items[i]]
            })
          }

        }
      }
      this.showThanks=true
    },

    //save review text in cookie/local storage
    tempSaveReview(item,value){
      localStorage.setItem(item,JSON.stringify(value))
    },
    checkLocalStorage(){
      let items=['generalText','llText','accuracyText','locText','cleanText']
      for (var i = 0; i < items.length; i++) {
        console.log(localStorage.getItem(items[i]), 'locl storage');
        if (localStorage.getItem(items[i])!= null){
          this[items[i]]=localStorage.getItem[items[i]]
        }
      }
    }
  },
  computed:{
    ...mapGetters({
      user: types.GET_USER
    }),
    landlordId(){
      if (this.$route.query.ll!= undefined){
        return this.$route.query.ll
      } else if (this.loading==false && this.hasOrder==true){
        return this.booking.landlordId
      } else {
        return null
      }
    },
    type(){
      if (this.$route.query.type!= undefined){
        return this.$route.query.type
      } else if (this.loading==false && this.hasOrder==true){
        return this.booking.type
      } else {
        return null
      }
    },
    roomId(){
      if (this.$route.query.id!= undefined){
        return this.$route.query.id
      } else if (this.loading==false && this.hasOrder==true){
        return this.booking.listingId
      } else {
        return null
      }
    },
    linkToRoom(){
      let link=""
      if (this.type=='studio'){
        link='/studioDetails/'+this.roomId
      } else if(this.type=='room'){
        link='/details/'+this.roomId
      } else if (this.type=='apartment'){
        link='/apartmentDetails/'+this.roomId+'/apartment'
      }
      return link
    },
    completion(){
      return this.cardNumber/this.totalCards*100
    }
  },
  created(){
    this.checkLocalStorage()
    if (this.user.reviewed==true) {
      this.showThanks=true
    }
  },
  components: {
    RatingDropzone,
  }
}
</script>

<style lang="scss" scoped>
.pab{
  padding-bottom: 5rem;
}
</style>
