<template>
  <div class="dropzoneContainer">
    <!-- <div class="uploadingContainer" v-if="count < 100"> -->
    <div class="uploadingContainer" :class="{'hidden': completed}">


      <vue-dropzone ref="myVueDropzone" id="dropzone" class="dropzone"
        @vdropzone-sending="vsending"
        @vdropzone-file-added="vfileAdded"
        @vdropzone-thumbnail="vReady"
        @vdropzone-removed-file="vfileRemoved"
        @vdropzone-complete="vcomplete"
        @vdropzone-queue-complete="vqueueComplete"
        @vdropzone-total-upload-progress="vprogress"
        @vdropzone-processing="vprocessing"
        @vdropzone-canceled="vcanceled"
        @vdropzone-error="verror"
        @vdropzone-success="vsuccess"
        :options="dropZoneOptions">
      </vue-dropzone>
        <!--
        @vdropzone-success="vsuccess"
        @vdropzone-error="verror"
        @vdropzone-removed-file="vremoved"
        @vdropzone-sending-multiple="vsendingMult"
        @vdropzone-success-multiple="vsuccessMuliple"
        @vdropzone-mounted="vmounted"
        @vdropzone-drop="vddrop"
        @vdropzone-drag-start="vdstart"
        @vdropzone-drag-end="vdend"
        @vdropzone-drag-enter="vdenter"
        @vdropzone-drag-over="vdover"
        @vdropzone-drag-leave="vdleave"
        -->

      <div class="sendingContainer">
        <v-btn :disabled="sending != 'no' || !enableUpload" class="md-primary md-raised"  @click="sendPhotos()">{{$t('dropzone.upload')}} {{buttonText}}</v-btn>
        <!-- <b-progress v-if="sending != 'no'" :value="counter" :max="100" :variant="counter == 100 ? 'success': 'default'" show-progress animated></b-progress> -->
        <v-progress-linear  v-if="sending != 'no'" v-model="counter" buffer-value="100" :color="counter == 100 ? 'success': 'primary'"></v-progress-linear>
      </div>
    </div>

    <div class="uploadedContainer" v-if="completed || uploadedFiles.length>0">
    <!-- <div class="uploadedContainer"> -->
      <v-toolbar dense>
        <v-toolbar-title>Your photos</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="reshowDropzone">+ Upload more</v-btn>
      </v-toolbar>
      <br>

      <v-layout  wrap>

      </v-layout>
      <v-container pa-1 grid-list-lg>
        <v-layout  wrap>

         <v-flex v-for="(file, index) in uploadedFiles" :key="file.id" xs6 sm4 >
             <v-img
             :src="'https://res.cloudinary.com/my-room-abroad/image/upload/f_webp,h_150/v1539854631/' + file.id" alt="Thumbnail"
             height="150"
             contain
             ></v-img>
             <v-btn color="error" :disable="file.disable" @click="removePhoto(file, index)">{{$t('dropzone.remove')}}</v-btn>

          </v-flex>
        </v-layout>
      </v-container>
    </div>


  </div>
</template>

<script>
import vueDropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
// import config from '../config'
import draggable from 'vuedraggable'

// vueDropzone.autoDiscover = false;

export default {
  name: 'dropzone',
  props: ['tag', 'landlordId', 'buttonText', 'photos', 'apartment'],
  components:{
    vueDropzone,
    draggable
  },
  data: function () {
  return {
    isApartment: false,
    onUpload: "",
    sending: 'no',
    counter: 100,
    count:0,
    // uploadedFiles: [
    //   {id: '1', url:'https://res.cloudinary.com/my-room-abroad/image/upload/v1533211075/-LI56ssUIBD2Wy9c3P_7/ovzq1fsxtrrdtfvhywn5.jpg', 'text':''},
    //   {id: '2', url:'https://res.cloudinary.com/my-room-abroad/image/upload/v1533211068/-LI56ssUIBD2Wy9c3P_7/qqyeew80ids6cqjkyjlu.jpg', 'text':''},
    // ],
    showDropzone : false,
    enableUpload: false,
    completed: false,
    //options for dropzone
    // // Instantiate Dropzone
    // dropzone : '',
    }
  },
  computed:{
    uploadedFiles:{
        get:function(){
          if (this.photos){
            return this.photos
          } else {
            return []
          }
        },
        set:function(val){
          if (val != this.photos){
            console.log('sort', val);
            this.$emit('onSorted', val)
          } else {
            console.log('val=== photos:', val, this.photos);
          }
        }
    },
    dropZoneOptions(){
      return {
        paramName: 'file',
        // The URL will be changed for each new file being processing
        url: 'https://api.cloudinary.com/v1_1/my-room-abroad/upload',
        // Customize the wording
        // We're going to process each file manually (see `accept` below)
        autoProcessQueue: false,
        addRemoveLinks: true,
        timeout:6000000,
        parallelUploads: 100,
        maxFilesize:15,
        maxFiles: 100,
        acceptedFiles:'.jpg, .jpeg, .png, .pdf',
        // resizeWidth: 1440,
        // parallelUploads: 1,

        accept (file, done) {
          done();
        },
        dictDefaultMessage:this.$t('dropzone.dropText'),
        dictRemoveFile:this.$t('dropzone.removeText'),
      }
    },


  },
  methods: {
    //button to manually send photos to sadicDve money and bandwidth
    sendPhotos(){
      // console.log('look at accepted', this.$refs.myVueDropzone.getAcceptedFiles());
      // console.log('look at queue', this.$refs.myVueDropzone.getQueuedFiles());
      this.$refs.myVueDropzone.processQueue();

    },
    checkQueue(){
      let amount = this.$refs.myVueDropzone.getAcceptedFiles();
      // console.log('amount vs amount2', amount, amount2);
      // console.log('amount in the queue', amount.length,
       amount.length >=1 ? true : false;
      this.enableUpload = amount.length >=1 ? true : false;
    },
    reshowDropzone(){
      this.completed = false;
      this.sending="no"
      this.count=0
      this.enableUpload = false
    },
    // send photos to Cloudinary using the landlord id as the folder
    vsending(file, xhr, formData){
      this.$emit("uploading",true)
      // console.log('vsending----------------', file);
      //show progress bar
        this.sending = 'yes'
        // url params
        formData.append('api_key', 648241524479494);
        formData.append('timestamp', Date.now() / 1000 | 0);
        formData.append('upload_preset', 'resizeandbrand');
        formData.append('folder', this.landlordId);
        // console.log('vsending end---', file, xhr, formData);

    },
    // send photos to Cloudinary using the landlord id as the folder
    sorted(){
      this.$emit('onSorted', this.uploadedFiles)
    },
    //show progress by percent
    vprogress(totaluploadprogress, totalBytes, totalBytesSent){
      //console.log('uploading progress-', totaluploadprogress);
      this.counter = totaluploadprogress;
    },
    //function if you want to do things when adding files
    vfileAdded(file){
      ++this.count
        // console.log('added -> ', file);
    },
    vReady(file){
      --this.count
      if (this.count==0){
        this.checkQueue()
      } else {
        this.enableUpload=false
      }
    },
    vfileRemoved(file){
        // console.log('removed -> ', file);
        this.checkQueue()
    },
    //function if you want to do things when adding files
    vcomplete(file){
        // console.log('completed -> ', file);
    },
    //function if you want to do things when adding files
    vprocessing(file){
        // console.log('processing -> ', file);
    },
    //function if you want to do things when adding files
    vcanceled(file){
        // console.log('canceled -> ', file);
    },
    //function if you want to do things when adding files
    verror(file, message, xhr){
        console.log('error -> ', file, message, file.xhr);
    },
    //function if you want to do things when adding files
    vsuccess(file, response){
        // console.log('success  -> ', file, response);
        // this.uploadedFiles.push({'id': response.public_id, 'url':response.url, 'text': ''})
        // TODO change this if using https
        // this.uploadedFiles.push(response.secure_url)
        console.log(response, file);
        this.$emit('onUpload', {'id': response.public_id, 'url':response.url, 'text': ''})
    },
    //what to do when files have all uploaded
    vqueueComplete(file, xhr, formData){
      // console.log('queue complete', file, xhr, formData);
      this.$refs.myVueDropzone.removeAllFiles(true);
      this.completed = true;
      this.sending="no"
      this.enableUpload=false
      this.$emit("uploading",false)
    },
    removePhoto(file, index){
      console.log('remove:', file, index);
      file.disable=true
      this.$emit('remove',file)

    }
  },
  watch:{
    photos: function(val, old){
      if(val != undefined) {
        if (old < 1 || old == undefined){
          this.uploadedFiles=val
        }
      }
    }
  },
  mounted () {
    // console.log('run dropzone mounted');
    const vm = this
    let uploading = false
    //update photos from server
    if(this.photos != undefined){
      // console.log('photos is defined');
      // console.log(this.photos)
      if(this.photos.length >= 1){
        // console.log('there is at least one picture');
        this.uploadedFiles = this.photos;
        this.completed = true;
      }
    }

    //change options for Apartment uploader vs Room uploader, room by default
    this.apartment != undefined ? this.isApartment = true : null;

  }
}
</script>

<style lang="scss" scoped>

.dropzoneContainer{

  .hidden{
    display: none!important;
  }

  .uploadingContainer{
    -webkit-transition: all 2s; /* For Safari 3.1 to 6.0 */
    transition: all 2s;
    &.hidden{
      opacity: 0;
      height: 0;
      overflow: hidden;
    }
  }
  .uploadedContainer{
    padding: 10px;

    .no-label-select{
      padding-top:0px;
      margin-top:0px;
    }
    .flex.photo-number{
      padding-bottom:0px !important;
    }
    h6{
      margin-top: 12px;
      font-size: 16px;
      color: #777;
      font-style: italic;
    }
    .item{
      min-width: 150px;
      strong{
        line-height: 32px;
      }
      .md-button{
        margin: 0;
        .md-ripple{
          -webkit-box-pack: flex-end;
          justify-content: flex-end;
          -webkit-box-align: center;
          align-items: center;
        }
      }
    }
    .md-layout-item{
      margin-right: 10px;
      margin-bottom: 10px;
      flex:0 1;
      &:hover{
        cursor: grab;
      }
      .thumbnailContainer{
        position: relative;
        padding: 5px;
        background: #fff;
        img{
          display: block;
          margin-bottom: 5px;
        }
        input{
          display: block;
        }
        .md-layout-item{
          margin: 0;
        }
      }
    }



  }
  .badge{
    position: absolute;
    color: #fff;
    height: 20px;
    width: 20px;
    line-height: 20px;
    padding: 0!important;
    border-radius: 50%;
    top: 0%;
    left: 0%;
    text-shadow: 1px 1px black;
    transform: scale(1.2,1.2);
    font-weight: bold;
    &.position{
      background: #00bfff;
      top:4px;
      left: 4px;
    }
  }
  .sendingContainer{
    margin-bottom: 24px;
  }
}

.dropzone {
	transition: all 0.2s linear;
	border: 2px dashed #ccc;
	background-color: #fafafa;
	min-height: initial;
  &:hover {
    border-color: #3498db;
    background-color: #fff;
    .dz-message .dropzone-title {
    	color: #3498db;
    }
  }
  .dz-message {
  	color: #666;
    span {
    	line-height: 1.8;
    	font-size: 13px;
    	letter-spacing: 0.4px;
    }
  }

}

</style>
